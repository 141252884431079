const config = require(`./config`)

export const getSocialLinks = (network, author) => {
  switch (network) {
    case "twitter":
      return `https://twitter.com/${author.twitter.replace(/^@/, ``)}/`
    case "facebook":
      return `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}/`
    default:
      return null
  }
}

export const targetBlank = () => {
  const siteUrl = config.siteUrl
  const getLinks = document.querySelectorAll(".post a, .filmLegalPage a")

  for (let i in getLinks) {
    if (
      getLinks[i].href &&
      getLinks[i].href.indexOf(siteUrl) === -1 &&
      getLinks[i].href.indexOf("filmustage.com") === -1 // Ensure the link does not contain "filmustage.com"
    ) {
      getLinks[i].target = "_blank"
      getLinks[i].rel = "noreferrer"
    }
  }
}
